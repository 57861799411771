import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import CaseStudyTemplate from '../../components/CaseStudyTemplate';

// assets
import Interaction1 from '../../images/case-study/flexnest/interaction1.mp4';
import Interaction2 from '../../images/case-study/flexnest/interaction2.mp4';
import Interaction3 from '../../images/case-study/flexnest/interaction3.mp4';
import Interaction4 from '../../images/case-study/flexnest/interaction4.mp4';
import Interaction5 from '../../images/case-study/flexnest/interaction5.mp4';
import Interaction6 from '../../images/case-study/flexnest/interaction6.mp4';
import Interaction7 from '../../images/case-study/flexnest/interaction7.mp4';
import Result from '../../images/case-study/flexnest/result.svg';

const FlexnestCaseStudy = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "case-study/flexnest/logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      hero: file(relativePath: { eq: "case-study/flexnest/hero.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      problem: file(relativePath: { eq: "case-study/flexnest/problem.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  const caseStudyData = {
    logo: data.logo.childImageSharp.gatsbyImageData,
    name: 'Flexnest',
    date: 'Nov 2020',
    title: (
      <div className="banner__title">
        Help you <span className="banner__title--highlight">stay in shape</span>{' '}
        while having fun
      </div>
    ),
    platforms: ['phone', 'web'],
    appLinks: [
      { type: 'play-store', path: '/' },
      { type: 'app-store', path: '/' },
    ],
    thumbnail: data.hero.childImageSharp.gatsbyImageData,
    description:
      'Ah that dreaded fitness resolution. We all have been there. But who knew staying in shape could be fun. Flexnest provides us just that - An app centered around fitness with a premium feel and an engaging experience. Let’s dive right in.',
    expandCTA: 'Tap to Flex',
    problem: [
      {
        text: 'Often times people tend to quit their fitness path due to lack of motivation and the enthusiasm that is necessary. So we put on our shoes and ran - to get a solution ofcourse :)',
        image: Interaction1,
      },
      {
        text: 'Also, we had to make a seamless user flow to work across all the flexnest products. From not overwhelming the user with details to having high accessibility, we had to get the details just right. “Whatever it takes !”',
        image: data.problem.childImageSharp.gatsbyImageData,
      },
    ],
    method: {
      text: 'The app must feel premium and fun to use. Also, we kept the visual identity of flexnest intact and used it to establish a harmonous layout and design.',
      image: Interaction2,
    },
    design: [
      {
        title: 'MOBILE APP',
        text: 'The flexnest brand sells high-end products and our design had to inculcate a variety of audience for it to work.',
        image: Interaction3,
      },
      { imageOne: Interaction4, imageTwo: Interaction5 },
      {
        title: 'Ipad and Desktop Design',
        text: 'You can take classes for different workouts in a structured way and live track your progress “I see this as an absolute win”',
        image: Interaction6,
      },
      {
        text: 'You can also take virtual rides while working out. A sunny beach or a dense forest - whatever you like',
        image: Interaction7,
      },
    ],
    result: {
      text: 'Flexnest helps you enhance your workout adventure - and we loved shaping that experience. “On your left”',
      image: Result,
    },
    next: ['moja', 'flexnest'],
  };

  return (
    <>
      <CaseStudyTemplate {...caseStudyData} />
    </>
  );
};

export default FlexnestCaseStudy;
